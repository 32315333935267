import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type UserState = {
  loginState: {
    isLoggedIn: boolean
    playerID?: string
  }
}

const initialState = {
  loginState: {
    isLoggedIn: false,
    playerID: undefined,
  },
} as UserState

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetFromStorage: (state: UserState, action: PayloadAction<UserState>) => {
      state.loginState = action.payload.loginState
    },
    login: (state, action: PayloadAction<string>) => {
      state.loginState.isLoggedIn = true
      state.loginState.playerID = action.payload
    },
    logout: (state) => {
      state.loginState.isLoggedIn = true
      state.loginState.playerID = undefined
    },
  },
})

export const { resetFromStorage, login, logout } = userSlice.actions

export default userSlice.reducer

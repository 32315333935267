import { isAnyOf } from '@reduxjs/toolkit';
import { startAppListening } from '../app/middleware';
import {
  addLetter,
  removeLetter,
  submitAnswer,
  resetGameFromStorage,
  resetPlayerState,
} from '../features/game/gameSlice';
import {
  markSeenTutorial,
} from '../features/nux/nuxSlice';
import {
  
} from '../features/verse/verseSlice';
import { GameStorage } from '../storage/game';


const listenStorageMiddleware = (gameStorage: GameStorage) => {
  startAppListening({
    matcher: isAnyOf(
      addLetter,
      removeLetter,
      submitAnswer,
      resetGameFromStorage,
      markSeenTutorial,
      resetPlayerState,
    ),
    effect: async (action, listenerApi) => {
      const { playerState, history, levelState } = listenerApi.getState().game;
      await gameStorage.saveObject({
          playerState,
          history,
          levelState,
      });
    }
  })
};

export default listenStorageMiddleware;

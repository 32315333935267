import { useLayoutEffect, useRef, useState } from 'react'

type SizeAwareDivProps = {
  className?: string
  key?: string
  render: (width: number, height: number) => JSX.Element
}

const SizeAwareDiv = ({ render, ...rest }: SizeAwareDivProps) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const checkBounds = () => {
      const divEl = ref.current
      if (divEl !== null) {
        setHeight(divEl.clientHeight)
        setWidth(divEl.clientWidth)
      }
    }
    window.addEventListener('resize', checkBounds)

    checkBounds()

    return () => {
      window.removeEventListener('resize', checkBounds)
    }
  })

  const ref = useRef<HTMLDivElement>(null)

  return (
    <div {...rest} ref={ref}>
      {render(width, height)}
    </div>
  )
}
export default SizeAwareDiv

import { format, parse } from 'date-fns';


const getDayOfYear = (date = new Date()) => {
  return +format(date, 'D', { useAdditionalDayOfYearTokens: true });
}

const parseDayOfYearToDate = (dayOfYear: number) => {
  return parse(dayOfYear.toString(), 'D', new Date(), { useAdditionalDayOfYearTokens: true });
};

export {
  getDayOfYear,
  parseDayOfYearToDate,
};
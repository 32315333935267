import './App.css';
import './skin/skin.css';
import './boilerplate/boilerplate.css';
import SettingsView from './views/SettingsView';
import HelpView from './views/HelpView';
import StatsView from './views/StatsView';
import GameView from './views/GameView';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  sawTutorial,
  selectInitialLoad,
  selectNeedsTutorial,
} from './features/game/gameSlice';
import classNames from 'classnames';
import * as playpass from 'playpass';
import { HELP_PATH, HOME_PATH, SETTINGS_PATH, STATS_PATH } from './Paths';
import { isGoogleBot } from './util/seo';

function App() {
  const needsTutorial = useAppSelector(selectNeedsTutorial) && !isGoogleBot();
  const initialLoadDone = useAppSelector(selectInitialLoad);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch()

  const toggleScreen = (screen: string) => {
    if (pathname === screen) {
      playpass.analytics.track('PageHide', { page: screen });
      playpass.analytics.track('PageShow', { page: '/' });
      navigate('/')
    } else {
      if (screen.startsWith(HELP_PATH)) {
        playpass.analytics.track('TutorialShow');
      }
      playpass.analytics.track('PageHide', { page: pathname });
      playpass.analytics.track('PageShow', { page: screen });
      navigate(screen);
    }
  };


  if (needsTutorial && pathname !== HELP_PATH) {
    return <Navigate replace to={HELP_PATH} />
  }

  if (!initialLoadDone) {
    return (
      <div className='loadingScreen'>
        <img src={'./logo192.png'} alt='' />
      </div>
    )
  }

  return (
    <div className='App'>
      <header>
        <nav>
          <div
            id='helpBtn'
            className='button'
            onClick={() => {
              toggleScreen(HELP_PATH);
            }}
          />
          <div
            // id='challengeBtn'
            className='button noclick'
            // onClick={() => {
            //   toggleScreen(CHALLENGE_PATH);
            // }}
          />

          <h1 className='title' onClick={() => {
            toggleScreen(HOME_PATH);
          }}>
            Bibdle
          </h1>

          <div
            id='statsBtn'
            className={classNames({
              active: pathname.startsWith(STATS_PATH),
              button: true,
            })}
            onClick={() => {
              toggleScreen(STATS_PATH);
            }}
          ></div>


          <div
            id='settingsBtn'
            className={classNames({
              active: pathname.startsWith(SETTINGS_PATH),
              button: true,
            })}
            onClick={() => {
              toggleScreen(SETTINGS_PATH)
            }}
          ></div>
        </nav>
      </header>
      <div className='mainArea'>
        <Routes>
          <Route
            path={HELP_PATH}
            element={
              <HelpView
                onHome={() => {
                  dispatch(sawTutorial())
                  playpass.analytics.track('TutorialHide')
                  toggleScreen(HELP_PATH)
                }}
                onBack={() => {
                  dispatch(sawTutorial())
                  playpass.analytics.track('TutorialHide')
                  toggleScreen(HELP_PATH)
                }}
              />
            }
          />
          <Route
            path={STATS_PATH}
            element={<StatsView onBack={() => toggleScreen(STATS_PATH)} />}
          />
          <Route
            path={SETTINGS_PATH}
            element={
              <SettingsView onBack={() => toggleScreen(SETTINGS_PATH)} />
            }
          />
          <Route path={HOME_PATH} element={<GameView />} />
          <Route path='*' element={<Navigate replace to={HOME_PATH} />} />
        </Routes>
      </div>
    </div>
  )
}

export default App;

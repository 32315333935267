import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectLoginState } from '../features/game/gameSlice'

import * as playpass from 'playpass'
import { login, logout } from '../features/user/userSlice'

const SettingsView = (props: { onBack: () => void }) => {
  const loggedInState = useAppSelector(selectLoginState)
  const dispatch = useAppDispatch()

  return (
    <div className="screen" id="settingsScreen">
      <h2>Settings</h2>
      {!loggedInState.isLoggedIn && (
        <div className="showIfLoggedOut">
          <div>Login to save or transfer your game progress.</div>
          <button
            id="loginBtn"
            onClick={async () => {
              const didLogin = await playpass.account.login()
              if (didLogin) {
                dispatch(login(playpass.account.getPlayerId()!))
              }
            }}
          >
            📱 Login
          </button>
        </div>
      )}
      {loggedInState.isLoggedIn && (
        <div className="showIfLoggedIn">
          <div>You are logged in, your progress will be saved.</div>
          <button
            id="logoutBtn"
            onClick={() => {
              playpass.account.logout()
              dispatch(logout())
            }}
          >
            Logout
          </button>
        </div>
      )}
      <button
        className="primary outline"
        id="settingsBackBtn"
        onClick={() => props.onBack()}
      >
        Back
      </button>
    </div>
  )
}
export default SettingsView

import { useMemo, type FC } from 'react';
import classNames from 'classnames';

import ModalBase from '../components/ModalBase';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectPlayerState,
  selectLevelState,
  selectGameState
} from '../features/game/gameSlice';
import {
  selectCurrentVerse,
  selectFullVerse,
  selectCurrentBook,
  startNextVerse,
} from '../features/verse/verseSlice';
import { onShareClick } from '../views/share';
import { selectModalIsOpen, setModalState } from '../features/modal/modalSlice';
import WeeklyProgress from '../components/WeeklyProgress';


const ResultsModal: FC = () => {

  const playerState = useAppSelector(selectPlayerState);
  const levelState = useAppSelector(selectLevelState);

  const gameState = useAppSelector(selectGameState);

  const currentVerse = useAppSelector(selectCurrentVerse);
  const fullVerse = useAppSelector(selectFullVerse);
  const currentBook = useAppSelector(selectCurrentBook);

  const resultsIsOpen = useAppSelector(selectModalIsOpen('results'));

  const dispatch = useAppDispatch()

  const gameFinished = gameState === 'WIN' || gameState === 'LOSS';
  
  const highlightedFullVerse = useMemo(() => {
    const words = fullVerse.split(' ');
    const returnVerse: JSX.Element[] = [];
    words.forEach((word, wordIndex) => {
      const sanitizedWord = word.replace(/[^A-Za-z]/gi, '');
      const sanitizedWordIsAnswer = sanitizedWord.toUpperCase() === levelState.word.toUpperCase();
      if (sanitizedWordIsAnswer) {
        const [maybePuncBefore, maybePuncAfter] = word.replace(sanitizedWord, ' ').split(' ');
        if (typeof maybePuncBefore !== 'undefined') {
          returnVerse.push(<div key={`maypunc_${wordIndex}`}>{maybePuncBefore}</div>);
        }
        returnVerse.push(<span key={`answer_${wordIndex}`}>{sanitizedWord}</span>);
        if (typeof maybePuncAfter !== 'undefined') {
          returnVerse.push(<div key={`maypunc_${wordIndex}`}>{maybePuncAfter}</div>);
        }
      } else {
        returnVerse.push(<div key={`${word}_${wordIndex}`}>{word}</div>);
      }
      returnVerse.push(<div key={`space_${wordIndex}`}>{' '}</div>)
    });
    return returnVerse;
  }, [fullVerse, levelState.word]);

  if (!resultsIsOpen) return null;

  return (
    <ModalBase className='resultsmodal' onClose={() => {
      dispatch(setModalState({ modal: 'results', isOpen: false }));
    }}>
      <div className='resultsmodal__header'>
        <h3 className='resultsmodal__header__title'>{'Today\'s Scripture'}</h3>
      </div>
      <div className='resultsmodal__content'>  
        <p className='resultsmodal__version'>{currentVerse.display} ({currentBook.abv})</p>
        <p className={classNames('resultsmodal__fullverse', {
          'solved': gameState === 'WIN' || gameState === 'LOSS'
        })}>{highlightedFullVerse}</p>
      </div>
      <WeeklyProgress />
      <div className='resultsmodal__actions'>
        {gameFinished ? (
          <>
            <button
              type='button'
              className='resultsmodal__actions__button resultsmodal__actions__button--share'
              onClick={() => {
                  onShareClick(
                    playerState,
                    levelState,
                    currentVerse,
                    gameState,
                  )
              }}
            >
              Share
            </button>
            <button
              type='button'
              className='resultsmodal__actions__button resultsmodal__actions__button--playnext'
              onClick={() => {
                dispatch(setModalState({
                  modal: 'results',
                  isOpen: false,
                }));
                dispatch(startNextVerse());
              }}
            >
              Play Next Verse
            </button>
          </>
        ) : (
          <button
            type='button'
            className='resultsmodal__actions__button resultsmodal__actions__button--continue'
            onClick={() => {
              dispatch(setModalState({
                modal: 'results',
                isOpen: false,
              }));
            }}
          >
            Continue Game
          </button>
        )}
      </div>
    </ModalBase>
  );
};

ResultsModal.displayName = 'ResultsModal';

export default ResultsModal;

import axios from 'axios';

interface GetVerseResponse {
  book: string;
  chapter: number;
  verse: number;
  content: string;
  nextVerseId: number;
};

const getVerseData = async (abv: string, verseId: string): Promise<GetVerseResponse> => 
  axios.post(`https://api.bibdle.org/bible/verse`, {
    abv,
    verseId,
  })
    .then((res) => res.data as GetVerseResponse);

export {
  getVerseData,
  type GetVerseResponse,
};

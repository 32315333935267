import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

// List of all the modals
interface Modals {
  info: boolean;
  challenge: boolean;
  results: boolean;
  settings: boolean;
}

// All other modal state
type ModalState = {
  shownResultsPerWin: boolean;
} & Modals;

const INITIAL_STATE: ModalState = {
  info: false,
  challenge: false,
  results: false,
  settings: false,
  shownResultsPerWin: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: INITIAL_STATE,
  reducers: {
    setModalState: (state, action: PayloadAction<{ modal: keyof Modals, isOpen: boolean }>) => {
      state[action.payload.modal] = action.payload.isOpen;
    },
    setShownResultsPerWin: (state, action: PayloadAction<boolean>) => {
      state.shownResultsPerWin = action.payload;
    }
  },
});

export const selectModalIsOpen = (key: keyof Modals) => (state: RootState) => state.modal[key];
export const selectShownResultsPerWin = (state: RootState) => state.modal.shownResultsPerWin;

export const {
  setModalState,
  setShownResultsPerWin,
} = modalSlice.actions;

export default modalSlice.reducer;

import { useEffect } from 'react'
import { useAppDispatch } from '../app/hooks'
import { ExampleGrid } from '../components/Grid'
import Panel from '../components/Panel'
import SizeAwareDiv from '../components/SizeAwareDiv'
import { markSeenTutorial } from '../features/nux/nuxSlice'


const HelpViewContent = (props: {
  onBack?: () => void
  onHome?: () => void
  width: number
  height: number
  profile?: string
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(markSeenTutorial());
  }, [dispatch]);
  return (
    <div className="screen" id="helpScreen">
      <Panel className="examples" showClose onClose={props.onBack}>
        <p className='help-title'>How to Play</p>
        <p className='gtw'>Guess the word</p>
        <p className='howtoplay'>You have 6 tries. Each guess should be a real word. Each guess will give you colored clues to how close you are.</p>
        <p className='hint'>
          <span className='green'>Green</span> means the word contains that correct letter in that correct spot.
        </p>
        <ExampleGrid
          marks="bbnnn"
          word="JEWEL"
          width={props.width}
          height={70}
        />

        <p className='hint'>
          <span className='yellow'>Yellow</span> means the word contains the letter, but in another spot.
        </p>
        <ExampleGrid
          marks="cncnn"
          word="ENJOY"
          width={props.width}
          height={70}
        />

        <p className='hint'>
          Combine what you learn from previous guesses to get the right answer!
        </p>
        <ExampleGrid
          marks="bbbbb"
          word="JESUS"
          width={props.width}
          height={70}
        />
        <div className='ugtp'>Unlimited games to play!</div>
      </Panel>
    </div>
  )
}

const HelpView = (props: {
  profile?: string
  onBack?: () => void
  onHome?: () => void
}) => {
  return (
    <SizeAwareDiv
      render={(width, height) => (
        <HelpViewContent
          profile={props.profile}
          width={width}
          height={height}
          onBack={props.onBack}
          onHome={props.onHome}
        />
      )}
    />
  )
}

export default HelpView

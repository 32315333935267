import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import Books from './books.json';

export interface VerseState {
  currentBook: {
    display: string;
    abv: string;
    id: string;
  },
  currentVerse: {
    display: string;
    id: string;
  },
  fullVerse: string,
  nextVerseId: string,
};

const INITIAL_STATE: VerseState = {
  currentBook: Books.KJV,
  currentVerse: {
    display: '',
    id: '',
  },
  fullVerse: '',
  nextVerseId: '',
};

export const verseSlice = createSlice({
  name: 'verse',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentVerse: (state, action: PayloadAction<{ id: string, display: string }>) => {
      state.currentVerse = action.payload;
    },
    setFullVerse: (state, action: PayloadAction<string>) => {
      state.fullVerse = action.payload;
    },
    setNextVerseId: (state, action: PayloadAction<string>) => {
      state.nextVerseId = action.payload;
    },
    resetVerseFromStorage: (state, action: PayloadAction<VerseState>) => {
      state.currentBook = action.payload.currentBook;
      state.currentVerse = action.payload.currentVerse;
      state.fullVerse = action.payload.fullVerse;
      state.nextVerseId = action.payload.nextVerseId;
    }
  },
});

// Selectors
export const selectCurrentVerse = (state: RootState) => state.verse.currentVerse;
export const selectFullVerse = (state: RootState) => state.verse.fullVerse;
export const selectCurrentBook = (state: RootState) => state.verse.currentBook;

// Actions
export const startDailyVerse = createAction<undefined>('verse/startDailyVerse');
export const startNextVerse = createAction<undefined>('verse/startNextVerse');
export const {
  setCurrentVerse,
  setFullVerse,
  setNextVerseId,
  resetVerseFromStorage,
} = verseSlice.actions;

export default verseSlice.reducer;

import {
  configureStore,
  ThunkAction,
  Action,
  createListenerMiddleware,
} from '@reduxjs/toolkit'
import gameReducer from '../features/game/gameSlice'
import nuxReducer from '../features/nux/nuxSlice'
import userReducer from '../features/user/userSlice'
import verseReducer from '../features/verse/verseSlice';
import modalReducer from '../features/modal/modalSlice';

export const listenerMiddleware = createListenerMiddleware()
export const store = configureStore({
  reducer: {
    game: gameReducer,
    nux: nuxReducer,
    user: userReducer,
    verse: verseReducer,
    modal: modalReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>


import { GameState } from '../features/game/gameSlice'
import * as playpass from 'playpass'
import type { ShareType } from 'playpass'
import { VerseState } from '../features/verse/verseSlice'

export function onShareClick(
  s: GameState['playerState'],
  l: GameState['levelState'],
  v: VerseState['currentVerse'],
  st: 'WIN' | 'LOSS' | 'LOADING' | 'PENDING',
  shareToTwitter?: boolean,
) {
  // Create a link to our game
  const link = playpass.createLink()

  const type = shareToTwitter ? ('twitter' as ShareType) : ('any' as ShareType)

  if (st === 'LOADING' || st === 'PENDING') {
    const text =
      `Bibdle #bibdle\n` +
      `Can you guess todays  word?` +
      '\n\n' +
      link
    playpass.share({ text, type })
    return
  }

  // Share some text along with our link
  const text =
    `Guess todays word!\n` +
    v.display + '\n\n' +
    '#bibdle ' +
    (st === 'LOSS' ? 'X' : s.marks.length.toString()) +
    '/' +
    l.guesses +
    '\n\n' +
    s.marks
      .map((str) =>
        str.replace(/n/g, '⬜').replace(/b/g, '🟩').replace(/c/g, '🟨'),
      )
      .join('\n') +
    '\n\n' +
    link

  playpass.share({ text, type })
}
import { startAppListening } from '../app/middleware';
import { markSeenTutorial } from '../features/nux/nuxSlice';
import { NuxStorage } from '../storage/nux';

const listenSaveMiddleware = (nuxStorage: NuxStorage) => {
  startAppListening({
    actionCreator: markSeenTutorial,
    effect: (action, listenerApi) => {
      const { nux: { initialLoad, ...simpleNuxState } } = listenerApi.getState();
      nuxStorage.saveObject(simpleNuxState);
    },
  });
};

export default listenSaveMiddleware;

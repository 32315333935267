import Verses from './versesNew.json';

import * as date from '../../util/date';

const VERSE_START_DATE = 173; // june 22
const verseKeys = Object.keys(Verses);

const getRandomVerse = () => {
  const randomIndex = Math.floor(Math.random() * verseKeys.length);
  const randomVerseKey = verseKeys[randomIndex] as keyof typeof Verses;
  return {
    id: randomVerseKey,
    display: Verses[randomVerseKey],
  };
};

// Get the word of the day, start with length 5, if there are no words, go to length 6, ect
const getRandomWord = (words: Record<number, string[]>) => {
  const wordLengths = [5, 6, 4, 3, 7, 2, 1];
  for(let i = 0; i < wordLengths.length; i++) {
    const len = wordLengths[i];
    if (words[len]) {
      return words[len][0];
    }
  }
  throw new Error('Verse map has no useable words');
}

const getVerseOfDay = () => {
  const today = date.getDayOfYear();

  // get index that loops on the start date
  const verseIndex = today >= VERSE_START_DATE ?
    today - VERSE_START_DATE :
    today + (365 - VERSE_START_DATE);

  const verseKey = verseKeys[verseIndex] as keyof typeof Verses;
  if (!verseKey) return;
  return {
    id: verseKey,
    display: Verses[verseKey],
  };
}

const hashVerseToWords = (fullVerse: string) => {
  const words = fullVerse.toUpperCase()
    .split(' ').map(word => word.replace(/[^A-Za-z]/gi, ''))
    .reduce<Record<number, string[]>>((acc, val) => {
      const num = val.length;
      if (Array.isArray(acc[num])) {
        acc[num].push(val);
      } else {
        acc[num] = [val];
      }
      return acc;
    }, {});
  return words;
};

const fromVerseStartDate = (day: number) => {
  return day - VERSE_START_DATE;
};

export {
  getRandomVerse,
  getRandomWord,
  getVerseOfDay,
  hashVerseToWords,
  fromVerseStartDate,
};

import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectGameState, selectIsHardMode, selectLevelState, selectPlayerState, setIsHardMode } from "../features/game/gameSlice";
import { selectCurrentBook, selectCurrentVerse, selectFullVerse } from "../features/verse/verseSlice";
import ToggleSwitch from './ToggleSwitch';

const VerseHintRevealer = () => {
  const currentVerse = useAppSelector(selectCurrentVerse);
  const fullVerse = useAppSelector(selectFullVerse);
  const currentBook = useAppSelector(selectCurrentBook);
  const levelState = useAppSelector(selectLevelState);
  const playerState = useAppSelector(selectPlayerState);
  const gameState = useAppSelector(selectGameState);
  const isHardMode = useAppSelector(selectIsHardMode);
  const dispatch = useAppDispatch();

  const verseParts = useMemo(() => {
    const verseWords = fullVerse.split(' ');
    const wordsPerPart = Math.ceil(verseWords.length / levelState.guesses);
    const parts: JSX.Element[][] = [];
    for(let i = 0; i < levelState.guesses; i++) {
      const hintPart: JSX.Element[] = [];
      for(let j = 0; j < wordsPerPart; j++) {
        const wordToAdd = verseWords.shift();
        if (wordToAdd) {
          const sanitizedWordToAdd = wordToAdd.replace(/[^A-Za-z]/gi, '');
          const sanitizedWordIsAnser = sanitizedWordToAdd.toUpperCase() === levelState.word.toUpperCase();
          // Sometimes words have puncuation at the end or begining (think commas, ect)
          // those are not part of the 'word'
          // so we want to pull the word out, but put the puncuation back in after.
          const [maybePuncBefore, maybePuncAfter] = wordToAdd.replace(sanitizedWordToAdd, ' ').split(' ');
          if (sanitizedWordIsAnser) {
            if (typeof maybePuncBefore !== 'undefined') {
              hintPart.push(<div key='puncbefore'>{maybePuncBefore}</div>);
            }
            hintPart.push(<span key={`${wordToAdd}_${j}`}>{sanitizedWordToAdd}</span>);
            if (typeof maybePuncAfter !== 'undefined') {
              hintPart.push(<div key='puncafter'>{maybePuncAfter}</div>);
            }
          } else {
            hintPart.push(<div key={`${wordToAdd}_${j}`}>{wordToAdd}</div>);
          }
          hintPart.push(<div key={`${j}_${i}_space`}>{' '}</div>);
        }
      }
      parts.push(hintPart);
    }

    return parts.map((part, index) => {
      const partKey = part.reduce((acc, val) => {
        acc += val.key;
        return acc;
      }, '');
      const unBlur =
        (!isHardMode) ||
        (gameState === 'WIN' || gameState === 'LOSS') ||
        ((playerState.words.length - 1) > index);

      return (
        <div
          key={partKey}
          className={unBlur ? 'unblur' : ''}
        >
          {part}
        </div>
      );
    });
  }, [fullVerse, levelState.guesses, playerState.words.length, gameState, levelState.word, isHardMode]);

  return (
    <div className='game-hint'>
      <div className='game-hint-head'>
        <div className='current-verse'>{currentVerse.display} ({currentBook.abv})</div>
        <ToggleSwitch
          name='hardmode'
          checked={isHardMode}
          onChange={(isChecked) => {
            dispatch(setIsHardMode(isChecked));
          }}
        />
      </div>
      <div className={`verse-hints ${(gameState === 'WIN' || gameState === 'LOSS') ? 'solved' : ''}`}>
        {verseParts}
      </div>
    </div>
  );
}

VerseHintRevealer.displayName = 'VerseHintRevealer';

export default VerseHintRevealer;

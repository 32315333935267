import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { isValidWord } from '../../boilerplate/dictionary'
import GameLogic from '../../GameLogic'

type WinRecord = { day: number; guesses: number; win: boolean }
type Wins = WinRecord[][]

function newWins(): Wins {
  return [[], [], [], [], [], [], [], [], [], []]
}

export type GameHistoryValue = {
  day: number // getOfYear 1-355
  marks: string[]
  maxGuesses: number
  imagePNGImageDataURL?: string
  nftURL?: string
}

export type GameState = {
  playerState: {
    words: string[]
    marks: string[]
    currentStreak: number
    maxStreak: number
    wins: Wins
    day: number
  }
  levelState: {
    word: string
    guesses: number
    day: number
    isDaily: boolean
  }
  needsTutorial: boolean
  gameState: 'WIN' | 'LOSS' | 'PENDING' | 'LOADING'
  message: {
    type: 'info' | 'reveal' | 'none'
    text: string
  }
  loginState: {
    isLoggedIn: boolean
    playerID?: string
  }
  history: Record<number, GameHistoryValue>
  isHardMode: boolean
}

export const initialState: GameState = {
  playerState: {
    words: [''],
    marks: [],
    currentStreak: 0,
    maxStreak: 0,
    wins: newWins(), // wins count for each successful attempt
    day: 0,
  },
  message: {
    type: 'none',
    text: '',
  },
  levelState: {
    day: -1,
    word: '',
    guesses: 0,
    isDaily: false,
  },
  needsTutorial: false,
  gameState: 'LOADING',
  loginState: {
    isLoggedIn: false,
    playerID: undefined,
  },
  history: {},
  isHardMode: false,
}

function getMessageForWin(guesses: number) {
  switch (guesses) {
    case 1:
      return 'Amen!'
    case 2:
      return 'Hallelujah!'
    case 3:
      return 'Blessings!'
    case 4:
      return 'Amen!'
    case 5:
      return 'Hallelujah!'
    default:
      return 'Blessings!'
  }
}

function clearMessage(state: GameState) {
  state.message.text = ''
  state.message.type = 'none'
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    clear: (state) => {
      state.gameState = initialState.gameState
      state.message = initialState.message
      state.levelState = initialState.levelState
      state.history = initialState.history
      state.playerState = initialState.playerState
    },
    resetGameFromStorage: (
      state,
      action: PayloadAction<Pick<GameState, 'playerState' | 'history'>>,
    ) => {
      if (state.levelState.guesses === 0) {
        throw new Error('Level state needs to be reset first')
      }

      state.playerState = action.payload.playerState
      state.history = action.payload.history

      if (GameLogic.isSolved(state.playerState)) {
        state.gameState = 'WIN'

        state.message.type = 'reveal'
        state.message.text = getMessageForWin(state.playerState.marks.length)
      } else if (GameLogic.isLost(state.playerState, state.levelState)) {
        state.gameState = 'LOSS'

        state.message.type = 'reveal'
        state.message.text = `Answer: ${state.levelState.word.toUpperCase()}`
      } else {
        state.gameState = 'PENDING'
        clearMessage(state)
      }
    },
    setMessage: (state, action: PayloadAction<GameState['message']>) => {
      state.message = action.payload
    },
    resetLevel: (state, action: PayloadAction<GameState['levelState']>) => {
      state.levelState.day = action.payload.day
      state.levelState.word = action.payload.word
      state.levelState.guesses = action.payload.guesses
      state.levelState.isDaily = action.payload.isDaily
      if (process.env.NODE_ENV === 'development') {
        console.log(`answer: ${action.payload.word}`);
      }
    },
    resetPlayerState: (state, action: PayloadAction<undefined>) => {
      state.playerState.words = [''];
      state.playerState.marks = [];
      state.gameState = 'PENDING';
      state.message = { ...initialState.message };
    },
    submitAnswer: (state) => {
      const word = state.playerState.words[state.playerState.words.length - 1]
      if (word.length < state.levelState.word.length) {
        state.message.type = 'info'
        state.message.text = 'Not enough letters'
        return
      }

      if (isValidWord(word) || word.toUpperCase() === state.levelState.word) {
        const result = GameLogic.getMarks(word, state.levelState.word)
        state.playerState.marks.push(result)

        const isWin = GameLogic.isSolved(state.playerState)
        const isLoss = GameLogic.isLost(state.playerState, state.levelState)

        if (isWin) {
          state.gameState = 'WIN'

          state.playerState.wins[state.playerState.marks.length - 1].push({
            win: true,
            day: state.playerState.day,
            guesses: state.playerState.marks.length,
          })
          if (state.levelState.isDaily) {
            const isStreaking = !!state.history[state.levelState.day - 1];
            state.playerState.currentStreak = isStreaking ?
              state.playerState.currentStreak + 1 :
              1;
            state.playerState.maxStreak = Math.max(
              state.playerState.currentStreak,
              state.playerState.maxStreak,
            );
          }

          state.message.type = 'reveal'
          state.message.text = getMessageForWin(state.playerState.marks.length)
        } else if (isLoss) {
          state.gameState = 'LOSS'

          state.playerState.wins[state.playerState.marks.length - 1].push({
            win: false,
            day: state.playerState.day,
            guesses: state.playerState.marks.length,
          })

          if (state.levelState.isDaily) {
            const isStreaking = !!state.history[state.levelState.day - 1];
            state.playerState.currentStreak = isStreaking ?
              state.playerState.currentStreak + 1 :
              0;
            state.playerState.maxStreak = Math.max(
              state.playerState.currentStreak,
              state.playerState.maxStreak,
            );
          }

          state.message.type = 'reveal'
          state.message.text = `Answer: ${state.levelState.word.toUpperCase()}`
        } else {
          if (state.playerState.words.length < state.levelState.guesses) {
            state.playerState.words.push('')
          }

          state.gameState = 'PENDING'
        }

        // Record history
        if (isWin || isLoss) {
          const day = state.levelState.day
          state.history[day] = {
            day: day,
            maxGuesses: state.levelState.guesses,
            marks: state.playerState.marks,
          }
        }
      } else {
        state.message.type = 'info'
        state.message.text = 'Not a valid word.'
      }
    },

    addLetter: (state, action: PayloadAction<string>) => {
      if (action.payload.length !== 1) {
        return
      }

      const word = state.playerState.words[state.playerState.words.length - 1]
      if (word.length + 1 > state.levelState.word.length) {
        return
      }
      state.playerState.words[state.playerState.words.length - 1] =
        word + action.payload

      clearMessage(state)
    },

    removeLetter: (state) => {
      const word = state.playerState.words[state.playerState.words.length - 1]

      if (word.length) {
        state.playerState.words[
          state.playerState.words.length - 1
        ] = word.substring(0, word.length - 1)
      }

      clearMessage(state)
    },

    needsTutorial: (state) => {
      state.needsTutorial = true
    },

    sawTutorial: (state) => {
      state.needsTutorial = false
    },

    setIsHardMode: (state, action: PayloadAction<boolean>) => {
      state.isHardMode = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
})

export const selectPlayerState = (state: RootState) => state.game.playerState
export const selectLevelState = (state: RootState) => state.game.levelState
export const selectNeedsTutorial = (state: RootState) =>
  state.game.needsTutorial
export const selectInitialLoad = (state: RootState) => state.nux.initialLoad
export const selectGameState = (state: RootState) => state.game.gameState
export const selectMessage = (state: RootState) => state.game.message
export const selectLoginState = (state: RootState) => state.game.loginState
export const selectHistory = (state: RootState) => state.game.history
export const selectIsHardMode = (state: RootState) => state.game.isHardMode

export const {
  clear,
  setMessage,
  needsTutorial,
  resetLevel,
  submitAnswer,
  addLetter,
  removeLetter,
  sawTutorial,
  resetGameFromStorage,
  resetPlayerState,
  setIsHardMode,
} = gameSlice.actions

export default gameSlice.reducer

import React, { type FC } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectHistory, selectLevelState, selectPlayerState } from '../features/game/gameSlice';
import * as date from '../util/date';

import { ReactComponent as CalNo } from '../skin/assets/cal-no.svg';
import { ReactComponent as CalYes } from '../skin/assets/cal-yes.svg';
import { isMonday, addDays, previousMonday } from 'date-fns';

const WeeklyProgress: FC = () => {
  const history = useAppSelector(selectHistory);
  const levelState = useAppSelector(selectLevelState);
  const playerState = useAppSelector(selectPlayerState);


  const today = date.parseDayOfYearToDate(levelState.day);
  const monday = isMonday(today) ? today : previousMonday(today);

  const week: Date[] = [monday];
  for (let i = 1; i < 7; i += 1) {
    week.push(addDays(monday, i));
  }

  return (
    <div className='weeklyprogress'>
      <div className='weeklyprogress__title'>Weekly Progress</div>
      {playerState.currentStreak > 0 && (
        <div className='weeklyprogress__ribbon'>
          <div className='weeklyprogress__ribbon__content'>
            {playerState.currentStreak} Day Streak
          </div>
        </div>
      )}
      <div className='weeklyprogress__week'>
        {week.map((day, dayIndex) => {
          const dayOfYear = date.getDayOfYear(day);
          const dayHistory = history[dayOfYear];

          let dayType: DayTypes = 'none';
          if (dayOfYear === levelState.day) {
            dayType = 'today';
          } else if (dayOfYear > levelState.day) {
            dayType = 'future';
          } else if (dayOfYear < levelState.day) {
            dayType = dayHistory ? 'progress' : 'none';
          }

          let iconType: IconTypes = 'none';
          if (dayOfYear < levelState.day) {
            iconType = dayHistory ? 'good' : 'bad';
          } else if (dayOfYear === levelState.day && dayHistory) {
            iconType = 'good';
          }

          return (
            <CalendarItem
              key={dayOfYear}
              day={dayIndex}
              dayType={dayType}
              iconType={iconType}
            />
          );
        })}
      </div>
    </div>
  );
};

WeeklyProgress.displayName = 'WeeklyProgress';

export default WeeklyProgress; 

type DayTypes = 'none' | 'progress' | 'today' | 'future';

const DAY_MAP = ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'];

const ICON_MAP = {
  none: null,
  bad: <CalNo />,
  good: <CalYes />,
};

type IconTypes = keyof typeof ICON_MAP;

const ITEM_CLASSES: Record<DayTypes, string> = {
  none: '',
  progress: 'weeklyprogress__calendaritem--progress',
  today: 'weeklyprogress__calendaritem--today',
  future: 'weeklyprogress__calendaritem--future'
};

const CalendarItem: FC<{ day: number, dayType?: DayTypes, iconType: IconTypes }> = ({ day, dayType = 'none', iconType = 'none' }) => {
  return (
    <div className={`weeklyprogress__calendaritem ${ITEM_CLASSES[dayType]}`}>
      <div className='weeklyprogress__calendaritem__content'>
        <div className='weeklyprogress__calendaritem__content__day'>
          {dayType === 'today' ? 'Today' : DAY_MAP[day]}
        </div>
        <div className='weeklyprogress__calendaritem__content__icon'>
          {ICON_MAP[iconType]}
        </div>
      </div>
    </div>
  );
};

CalendarItem.displayName = 'CalendarItem';

import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import CloseIcon from '../skin/assets/icon-close.svg';

const modalRoot = document.getElementById('modal-root');
if (!modalRoot) throw new Error('div#modal is missing from html');

interface Props {
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
}

const ModalBase: React.FC<Props> = ({ children, className, onClose }) => {
  const divElement = useRef(document.createElement('div')).current as HTMLDivElement;
  useLayoutEffect(() => {
    modalRoot.appendChild(divElement);
    return () => {
      modalRoot.removeChild(divElement);
    }
  }, [divElement]);
  useLayoutEffect(() => {
    let cn = ['modal'];
    if (className) {
      cn = [...cn, ...className.split(' ')];
    }
    divElement.classList.add(...cn);
    return () => {
      divElement.classList.remove(...cn);
    }
  }, [className, divElement]);
  useLayoutEffect(() => {
    document.body.classList.add('modalisopen');
    return () => {
      document.body.classList.remove('modalisopen');
    };
  });
  useEffect(() => {
    const maybeClose = (event: MouseEvent) => {
      if ((event.target as HTMLDivElement).matches('.modal')){
        onClose();
      }
    };
    document.addEventListener('click', maybeClose);
    return () => {
      document.removeEventListener('click', maybeClose);
    };
  }, [onClose]);
  return (
    createPortal(
      <div className='modal__container'>
        <button className='modal__container__closebtn' type='button' onClick={onClose}>
          <img src={CloseIcon} alt='close icon' />
        </button>     
        { children }
      </div>,
      divElement
    )
  );
};

ModalBase.displayName = 'ModalBase';

export default ModalBase;

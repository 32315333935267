import Keyboard from '../components/Keyboard';
import { ResizableGrid } from '../components/Grid';
import GameLogic from '../GameLogic';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import classNames from 'classnames';
import {
  addLetter,
  GameState,
  removeLetter,
  selectGameState,
  selectLevelState,
  selectMessage,
  selectPlayerState,
  submitAnswer,
} from '../features/game/gameSlice';
import SizeAwareDiv from '../components/SizeAwareDiv';
import { selectCurrentVerse, startNextVerse } from '../features/verse/verseSlice';
import { onShareClick } from './share';

import VerseHintRevealer from '../components/VerseHintRevealer';

import WeeklyProgress from '../components/WeeklyProgress';


const PlayView = (props: {
  setup: GameState['levelState']
  state: GameState['playerState']
  message: GameState['message']
  onKey: (e: string) => void
}) => {
  const { state, setup } = props;

  const gameState = useAppSelector(selectGameState);
  const dispatch = useAppDispatch();
  const playerState = useAppSelector(selectPlayerState);
  const levelState = useAppSelector(selectLevelState);
  const currentVerse = useAppSelector(selectCurrentVerse);

  return (
    <div className="gameScreen" id="playingScreen">
      <VerseHintRevealer />
      <SizeAwareDiv
        key={levelState.word} // force refresh when the word changes
        className="primary"
        render={(width, height) => {
          return (
            <ResizableGrid
              width={width}
              height={height}
              enableScrollable={true}
              state={state} // force re-render
              length={setup.word.length}
              guesses={setup.guesses}
            />
          )
        }}
      />
      <div className="bottom">
        <div className="keyboardContainer">
          <Keyboard
            state={state}
            onKey={(e) => props.onKey(e)}
            message={
              props.message.type === 'info' ? props.message.text : undefined
            }
          />
        </div>
          <div
            className={classNames('result-container', {
              'open': gameState === 'WIN' || gameState === 'LOSS',
              'with-stats': true,
            })}
          >
            <div className='result-container-content'>
              <div className='result-msg'>{props.message.text}</div>
              <WeeklyProgress />
              <div className='share-msg'>
                {gameState === 'LOSS' ? 'Better luck next time! ' : ''}
                Share the good word with your friends and family!
              </div>
              <div className='action-group'>
                <button
                  type='button'
                  onClick={() => {
                      onShareClick(
                        playerState,
                        levelState,
                        currentVerse,
                        gameState,
                      )
                  }}
                >
                  Share
                </button>
                <button
                  type='button'
                  onClick={() => {
                    dispatch(startNextVerse());
                  }}
                >
                  Play Next Verse
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

const GameView = () => {
  const playerState = useAppSelector(selectPlayerState);
  const levelState = useAppSelector(selectLevelState);
  const gameState = useAppSelector(selectGameState);
  const messageState = useAppSelector(selectMessage);

  const dispatch = useAppDispatch();

  const handleKeyEvent = (key: string) => {
    if (
      GameLogic.isSolved(playerState) ||
      playerState.marks.length === levelState.guesses
    ) {
      return;
    }

    if (key === 'Enter') {
      dispatch(submitAnswer());
    } else if (key === 'Delete') {
      dispatch(removeLetter());
    } else {
      dispatch(addLetter(key));
    }
  }

  if (
    gameState === 'LOADING'
  ) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PlayView
        onKey={(e) => handleKeyEvent(e)}
        state={playerState}
        setup={levelState}
        message={messageState}
      />
    </>
  )
}

export default GameView;

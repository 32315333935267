import * as playpass from 'playpass';
import { VerseState } from '../features/verse/verseSlice';

const TAG = 'verse1';

export class VerseStorage {

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<VerseState | undefined> {
    try {
      const state = (await playpass.storage.get(TAG)) as VerseState;
      if (state) {
        return state;
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: VerseState) {
    await playpass.storage.set(TAG, state);
  }
}

import { isAnyOf } from '@reduxjs/toolkit';
import * as playpass from 'playpass';
import { startAppListening } from '../app/middleware';
import {
  addLetter,
  needsTutorial,
  removeLetter,
  submitAnswer,
  resetGameFromStorage,
  setIsHardMode,
} from '../features/game/gameSlice';
import { markSeenTutorial } from '../features/nux/nuxSlice';
import GameLogic from '../GameLogic';


const listenAnalyticsMiddleware = () => {
  startAppListening({
    matcher: isAnyOf(
      needsTutorial,
      addLetter,
      removeLetter,
      submitAnswer,
      markSeenTutorial,
      resetGameFromStorage,
      setIsHardMode,
    ),
    effect: (action, listenerApi) => {
      const { game: { playerState, levelState, isHardMode }, verse } = listenerApi.getState();
      const { playerState: oldPlayerState } = listenerApi.getOriginalState().game;

      switch (action.type) {

        case needsTutorial.type:
          playpass.analytics.track('TutorialShow');
          break;

        case resetGameFromStorage.type:
          playpass.analytics.track('PuzzleStart', {
            guessedWords: playerState.marks.length,
            day: playerState.day,
            verse: verse.currentVerse.id,
            currentStreak: playerState.currentStreak,
            isHardMode: isHardMode,
          });
          if (
            GameLogic.isSolved(playerState) ||
            GameLogic.isLost(playerState, levelState)
          ) {
            playpass.analytics.track('PuzzleComplete', {
              guessedWords: playerState.marks.length,
              day: playerState.day,
              verse: verse.currentVerse.id,
              currentStreak: playerState.currentStreak,
              isHardMode: isHardMode,
            });
          }
          break;
        
        case addLetter.type:
          playpass.analytics.track('AddLetter', {
            currentWord: playerState.words[playerState.words.length - 1],
          });
          break;

        case removeLetter.type:
          playpass.analytics.track('RemoveLetter', {
            currentWord: playerState.words[playerState.words.length - 1],
          });
          break;
        
        case submitAnswer.type:
          if (oldPlayerState.marks.length !== playerState.marks.length) {
            playpass.analytics.track('RowComplete', {
              row: playerState.marks.length,
            });
          }
          if (
            GameLogic.isSolved(playerState) ||
            GameLogic.isLost(playerState, levelState)
          ) {
            playpass.analytics.track('PuzzleComplete', {
              guessedWords: playerState.marks.length,
              day: playerState.day,
            });
          }
          break;
        
        case setIsHardMode.type:
          playpass.analytics.track('EasyHardToggle', {
            isHardMode: isHardMode,
          });
          break;

      }; // end switch

    },
  })
};

export default listenAnalyticsMiddleware;

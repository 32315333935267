import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type NuxState = {
  seenTutorial: boolean
  initialLoad: boolean
}

const initialState = { seenTutorial: false, initialLoad: false } as NuxState

export const nuxSlice = createSlice({
  name: 'nux',
  initialState,
  reducers: {
    resetFromStorage: (
      state: NuxState,
      action: PayloadAction<Omit<NuxState, 'initialLoad'>>,
    ) => {
      state.seenTutorial = action.payload.seenTutorial
    },

    markSeenTutorial: (state) => {
      state.seenTutorial = true
    },

    markInitialLoad: (state) => {
      state.initialLoad = true
    },
  },
})

export const {
  resetFromStorage,
  markSeenTutorial,
  markInitialLoad,
} = nuxSlice.actions

export default nuxSlice.reducer

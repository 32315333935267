import { isAnyOf } from "@reduxjs/toolkit";
import { startAppListening } from "../app/middleware";
import { login, logout } from "../features/user/userSlice";

const listenUserMiddleware = (reload: () => Promise<any>) => {
  startAppListening({
    matcher: isAnyOf(login, logout),
    effect: (action, listenerApi) => {
      const { loginState: oldLoginState } = listenerApi.getOriginalState().game;
      const { loginState } = listenerApi.getState().game;

      if (
        oldLoginState.isLoggedIn !== loginState.isLoggedIn ||
        oldLoginState.playerID !== loginState.playerID
      ) {
        console.log('reloading game state...');
        reload().catch(console.error);
      }
    },
  });
};

export default listenUserMiddleware;

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import * as playpass from 'playpass'
import { HashRouter } from 'react-router-dom'

import {
  needsTutorial,
  resetGameFromStorage,
  resetLevel,
} from './features/game/gameSlice'
import {
  markInitialLoad,
  resetFromStorage as resetNuxFromStorage,
} from './features/nux/nuxSlice'
import { login } from './features/user/userSlice'
import { startDailyVerse, resetVerseFromStorage } from './features/verse/verseSlice'

import { NuxStorage } from './storage/nux';
import { initGCInstant } from 'playpass/dist/esm/gcinstant'

// import { initGTM } from 'playpass/dist/esm/gtm';
import { GameStorage } from './storage/game'

import listenAnalyticsMiddleware from './middleware/analyticsMiddleware'
import listenNuxMiddleware from './middleware/nuxMiddleware'
import listenUserMiddleware from './middleware/userMiddleware'
import listenVerseMiddleware from './middleware/verseMiddleware'
import listenStorageMiddleware from './middleware/storageMiddleware'

import ResultsModal from './modals/ResultsModal';
import { VerseStorage } from './storage/verse'

import * as date from './util/date';
import { abTestConfig } from './util/abtest'
import { makeRandomGenerator } from './util/prng'

declare const AMPLITUDE_KEY: string;

const nuxStorage: NuxStorage = new NuxStorage();
const gameStorage: GameStorage = new GameStorage();
const verseStorage: VerseStorage = new VerseStorage();

const reload = async () => {

  const nuxState = await nuxStorage.loadObject();
  const verseState = await verseStorage.loadObject();
  const gameState = await gameStorage.loadObject();

  if (nuxState) {
    store.dispatch(resetNuxFromStorage(nuxState));
  }
  if (verseState) {
    store.dispatch(resetVerseFromStorage(verseState));
  }

  const currentDayOfYear = date.getDayOfYear();

  const currentDay = gameState?.playerState.day ?? -1;

  if (nuxState && gameState && verseState && currentDay === currentDayOfYear) {
    console.log('loading from storage');
    store.dispatch(resetVerseFromStorage(verseState));
    store.dispatch(resetLevel({
      word: gameState.levelState.word,
      guesses: gameState.levelState.guesses,
      day: gameState.levelState.day,
      isDaily: gameState.levelState.isDaily,
    }));
    store.dispatch(resetGameFromStorage(gameState));
  } else {
    console.log('getting daily verse');
    store.dispatch(startDailyVerse());
  }

  const sawTutorial = await playpass.storage.get('sawTutorial')
  if (!sawTutorial) {
    void playpass.storage.set('sawTutorial', true)
    store.dispatch(needsTutorial())
  }

  if (playpass.account.isLoggedIn()) {
    store.dispatch(login(playpass.account.getPlayerId()!))
  }

  store.dispatch(markInitialLoad())
}

const setup = async () => {
  // NEEDS new GTM
  // initGTM({ tagId: 'GTM-MVW4XC7' })
  await playpass.init({ gameId: '911fa13c-8b91-4d8e-bdd9-76b40d415964' })

  // setup analytics
  await initGCInstant({
    abTestConfig,
    amplitude: AMPLITUDE_KEY, // Injected via webpack
    hashFunction: makeRandomGenerator
  })

  // setup redux middleware
  listenNuxMiddleware(nuxStorage)
  listenUserMiddleware(reload)
  listenAnalyticsMiddleware()
  listenVerseMiddleware(gameStorage);
  listenStorageMiddleware(gameStorage);

  await reload()
}

setup().catch(console.error)

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
        <ResultsModal />
      </HashRouter>
    </Provider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// export const HardMode = '0001_bibdle_hard_mode';
// export const ModalWinScreen = '0002_bibdle_win_screen';
// export const ModalWinScreenv2 = '0003_bibdle_win_screen_v2';
// export const HardModev2 = '0004_bibdle_hard_mode_v2';

export const abTestConfig = {
  // [HardModev2]: {
  //   active: true,
  //   default: 'control',
  //   buckets: [
  //     {
  //       id: 'control',
  //     },
  //     {
  //       id: 'StartHardMode'
  //     },
  //     {
  //       id: 'StartEasyMode',
  //     }
  //   ],
  // },
  // [ModalWinScreenv2]: {
  //   active: true,
  //   default: 'control',
  //   buckets: [
  //     {
  //       id: 'control',
  //     },
  //     {
  //       id: 'ControlWithStats',
  //     },
  //     {
  //       id: 'UseModalWinScreen'
  //     },
  //   ],
  // },
};

import { startAppListening } from '../app/middleware';
import { setCurrentVerse, setFullVerse, setNextVerseId, startDailyVerse, startNextVerse } from '../features/verse/verseSlice';
import { getRandomVerse, getRandomWord, getVerseOfDay, hashVerseToWords } from '../features/verse/verseUtils';
import { getVerseData, GetVerseResponse } from '../features/verse/verseAPI';
import {
  resetGameFromStorage,
  resetLevel,
  resetPlayerState,
  initialState,
} from '../features/game/gameSlice';
import { isAnyOf } from '@reduxjs/toolkit';
import { VerseStorage } from '../storage/verse';
import { GameStorage } from '../storage/game';
import * as date from '../util/date';
import { setShownResultsPerWin } from '../features/modal/modalSlice';

const listenVerseMiddleware = (gameStorage: GameStorage) => {
  startAppListening({
    actionCreator: startDailyVerse,
    effect: async function startDailyVerseEffect (action, listenerApi) {

      const maybeGameState = await gameStorage.loadObject();

      const { currentBook } = listenerApi.getState().verse;
      let nextVerse = getVerseOfDay();
      if (!nextVerse) {
        nextVerse = getRandomVerse();
      }

      listenerApi.dispatch(setCurrentVerse(nextVerse));

      let data: GetVerseResponse;
      try {
        data = await getVerseData(currentBook.abv, nextVerse.id);
      } catch (e) {
        console.error(e);
        return;
      }
      
      const fullVerse = data.content.replace('\n', '').trim();
      const words = hashVerseToWords(fullVerse);
  
      listenerApi.dispatch(setFullVerse(fullVerse));
      listenerApi.dispatch(setNextVerseId(data.nextVerseId.toString()));
  
      const wordToGuess = getRandomWord(words);

      // These have to be called at the same time in this order?
      listenerApi.dispatch(resetLevel({
        word: wordToGuess,
        guesses: 6,
        day: date.getDayOfYear(),
        isDaily: true,
      }));
      // If there is gamestate, merge it with initial state and set the day
      // else set initial state and set the day
      if (maybeGameState) {
        listenerApi.dispatch(resetGameFromStorage({
          playerState: {
            ...initialState.playerState,
            currentStreak: maybeGameState.playerState.currentStreak,
            maxStreak: maybeGameState.playerState.maxStreak,
            wins: maybeGameState.playerState.wins,
            day: date.getDayOfYear(),
          },
          history: {
            ...initialState.history,
            ...maybeGameState.history
          }
        }));
      } else {
        listenerApi.dispatch(resetGameFromStorage({
          playerState: {
            ...initialState.playerState,
            day: date.getDayOfYear(),
          },
          history: {
            ...initialState.history,
          },
        }));
      }

    },
  });
  startAppListening({
    actionCreator: startNextVerse,
    effect: async function startNextVerseEffect(action, listenerApi) {

      const { currentBook, nextVerseId } = listenerApi.getState().verse;

      let data: GetVerseResponse;
      try {
        data = await getVerseData(currentBook.abv, nextVerseId);
      } catch (e) {
        console.error(e);
        return;
      }

      listenerApi.dispatch(setCurrentVerse({
        id: nextVerseId,
        display: `${data.book} ${data.chapter}:${data.verse}`,
      }));

      const fullVerse = data.content.replace('\n', '').trim();
      const words = hashVerseToWords(fullVerse);

      listenerApi.dispatch(setFullVerse(fullVerse));
      listenerApi.dispatch(setNextVerseId(data.nextVerseId.toString()));

      const wordToGuess = getRandomWord(words);

      // These have to be called at the same time in this order?
      listenerApi.dispatch(resetLevel({
        word: wordToGuess,
        guesses: 6,
        day: date.getDayOfYear(),
        isDaily: false,
      }));
      listenerApi.dispatch(resetPlayerState());
      listenerApi.dispatch(setShownResultsPerWin(false));

    },
  });
  startAppListening({
    matcher: isAnyOf(
      setCurrentVerse,
      setFullVerse,
      setNextVerseId,
    ),
    effect: (action, listenerApi) => {
      const { verse } = listenerApi.getState();
      const verseStorage = new VerseStorage();
      verseStorage.saveObject(verse);
    },
  });
};

export default listenVerseMiddleware;

// FNV1a hash function,
// fast but not very great at anything.

function fnv1a(str: string) {
  let h = 0x811c9dc5 >>> 0;

  if (str) {
      for (let i = 0, l = str.length; i < l; i++) {
          h = (h ^ str.charCodeAt(i)) >>> 0;

          const incr = (h << 1) + (h << 4) + (h << 7) + (h << 8) + (h << 24);

          h = (h + incr) >>> 0;
      }
  }

  return h;
}

// Tiny encryption algorithm
// as a small & simple hash function with very good PRNG qualities.

// Parallel Random Generator - GDC 2015
// https://www.slideshare.net/ManchorKo/parallel-random-generation-mannyko

/* eslint-disable no-bitwise */

function tea(s: { v0: number; v1: number; }) {
  let v0 = s.v0;
  let v1 = s.v1;

  let sum = 0;
  const delta = 0x9e3779b9;

  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);

  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);

  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >>> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >>> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >> 5) + 0x7e95761e);

  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >> 5) + 0x7e95761e);
  sum += delta;
  v0 += ((v1 << 4) + 0xa341316c) ^ (v1 + sum) ^ ((v1 >> 5) + 0xc8013ea4);
  v1 += ((v0 << 4) + 0xad90777d) ^ (v0 + sum) ^ ((v0 >> 5) + 0x7e95761e);

  s.v0 = v0;
  s.v1 = v1;
}

function teaHash(v0: string | number, v1 = 0) {
  if (typeof v0 === 'string') {
      v0 = fnv1a(v0) | 0;
  }

  const s = { v0, v1 };
  tea(s);

  return (s.v0 & 0xffffff) / 0x1000000;
}

export function makeRandomGenerator(seed: string | number) {
  let n = 0;

  return () => teaHash(seed, n++);
}

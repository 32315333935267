import * as playpass from 'playpass'
import { NuxState } from '../features/nux/nuxSlice'

const TAG = 'nux1'

type SimpleNuxState = Omit<NuxState, 'initialLoad'>;

export class NuxStorage {

  /** Loads an object from storage, returning null if there was no object previously saved today. */
  async loadObject(): Promise<SimpleNuxState | undefined> {
    try {
      const state = (await playpass.storage.get(TAG)) as SimpleNuxState | undefined
      if (state) {
        return state;
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: SimpleNuxState) {
    await playpass.storage.set(TAG, state)
  }
}

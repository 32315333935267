import * as playpass from 'playpass'
import { GameState } from '../features/game/gameSlice';

const TAG_SUFFIX = 'game1'

type SerializedState = Pick<GameState, 'playerState' | 'history' | 'levelState'>

export class GameStorage {

  async loadObject(): Promise<SerializedState | undefined> {
    try {
      const state = (await playpass.storage.get(TAG_SUFFIX)) as SerializedState;
      if (state) {
        return state;
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  /** Saves an object to storage, which will expire the next day. */
  async saveObject(state: SerializedState) {
    await playpass.storage.set(TAG_SUFFIX, state);
  }

}
